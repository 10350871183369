import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosError, AxiosRequestConfig } from 'axios'
import { ServiceErrorHandler } from '@/connect-types/backend/service'
import { customAxios } from '@/utils/axiosHelper'

export type StampedeService =
  | 'morpheus'
  | 'zion'
  | 'trinity'
  | 'service'
  | 'integration'
  | 'loyalty'
export const axiosBaseQuery =
  (
    { baseUrl, service }: { baseUrl: string; service: StampedeService } = {
      baseUrl: '',
      service: 'morpheus',
    }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      let axiosUrl = baseUrl + url
      if (service === 'morpheus' && 'https://') {
        axiosUrl = url
      }
      const result = await customAxios({
        url: axiosUrl,
        method,
        data,
        params,
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      console.log(err, 'error-here')
      if (
        method &&
        method.toUpperCase() !== 'GET' &&
        err.response?.status !== 401 &&
        err.response?.status !== 404 &&
        !url.includes('segment') &&
        !url.includes('oauth')
      ) {
        ServiceErrorHandler(err)
      }

      if (service === 'service' && method && method.toUpperCase() !== 'GET') {
        ServiceErrorHandler(axiosError)
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

export const shouldBailout = (err: AxiosError) => {
  if (!err?.response) return false
  return err.response.status >= 400 && err.response.status < 500
}
