'use client'
import { ElementType, Fragment, forwardRef } from 'react'
import { Switch as HSwitch, SwitchProps } from '@headlessui/react' // Assuming you're using @headlessui
import { DetailedHTMLProps } from 'react'
import { cn } from '../..'

type MyToggleProps = {
  brandKit?: boolean
  label?: { true: string; false: string } | null
  classNames?: {
    container?: string
  }
} & SwitchProps<HTMLInputElement>

const Switch = forwardRef<HTMLInputElement, MyToggleProps>(
  (
    {
      brandKit = false,
      label = { true: 'On', false: 'Off' },
      classNames = { container: '' },
      ...rest
    },
    ref
  ) => {
    return (
      <HSwitch.Group>
        <div className={cn('flex items-center', classNames.container)}>
          {label && (
            <HSwitch.Label className="mr-4 capitalize block text-sm font-medium leading-6">
              {rest?.checked ? label.true : label.false}
            </HSwitch.Label>
          )}

          <HSwitch as={Fragment} ref={ref} {...rest}>
            {({ checked }) => (
              <button
                className={`${
                  checked
                    ? brandKit
                      ? 'bg-interface-500'
                      : 'bg-stampede-500'
                    : 'bg-gray-300 dark:bg-gray-800'
                } relative border inline-flex h-6 w-12 items-center rounded-full`}
              >
                <span
                  className={`${
                    checked ? 'translate-x-7 dark:bg-white' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white dark:bg-gray-300 transition`}
                />
              </button>
            )}
          </HSwitch>
        </div>
      </HSwitch.Group>
    )
  }
)

export default Switch

Switch.displayName = 'Switch'
