import {
  BreadcrumbEllipsis,
  BreadcrumbLink,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'tailwind-ui'
import { NavLink } from 'react-router-dom'
import Index from '@/pages'
import RootLayout from '@/pages/layout'
import RootOrgLayout from '@/pages/[org_id]/layout'
import type { NormalRoutesType } from './types'

export const newRoutesArray: NormalRoutesType[] = [
  {
    path: '/',
    component: <RootLayout />,
    children: [
      {
        index: true,
        component: '',
      },
      {
        path: 'marketing/senders',
        component: 'marketing/senders',
      },
      {
        path: 'marketing/email-verification',
        component: 'marketing/senders',
      },
      {
        path: 'design-components',
        component: 'design/components',
      },
      {
        path: 'account-invite/:invite_id',
        component: 'account-invite/[invite_id]/layout',
        children: [
          {
            path: 'accept',
            component: 'account-invite/[invite_id]/accept',
          },
          {
            path: 'decline',
            component: 'account-invite/[invite_id]/decline',
          },
        ],
      },
      {
        path: 'code',
        component: 'oauth/code',
      },
      {
        path: 'oauth',
        component: 'oauth/layout',
        children: [
          {
            path: 'logout',
            component: 'oauth/logout',
          },
          {
            path: 'code',
            component: 'oauth/code',
          },
        ],
      },
      {
        path: 'admin',
        component: 'admin/layout',
        children: [
          {
            index: true,
            component: 'admin',
          },
          {
            path: 'venues',
            component: 'admin/venues/layout',
            children: [
              {
                index: true,
                component: 'admin/venues',
              },
              {
                path: 'wall',
                component: 'admin/venues/wall',
              },
            ],
          },
          {
            path: 'queue',
            component: 'admin/queue/layout',
            children: [
              {
                index: true,
                component: 'admin/queue',
              },
              {
                path: 'failed',
                component: 'admin/queue/failed',
              },
              {
                path: 'slow-queries',
                component: 'admin/queue/slow-queries',
              },
            ],
          },
          {
            path: 'marketing',
            component: 'admin/marketing/layout',
            children: [
              {
                index: true,
                component: 'admin/marketing',
              },
              {
                path: 'email',
                component: 'admin/marketing/email',
              },
            ],
          },
          {
            path: 'interactions',
            component: 'admin/interactions',
          },
          {
            path: 'create',
            component: 'admin/create/layout',
            children: [
              {
                index: true,
                component: 'admin/create',
              },
              {
                path: 'account',
                component: 'admin/create/account',
              },
            ],
          },

          {
            path: 'organisations',
            component: 'admin/organisations/layout',
            children: [
              {
                index: true,
                component: 'admin/organisations',
              },
              {
                path: ':org_id',
                component: 'admin/organisations/[org_id]/layout',
                children: [
                  {
                    index: true,
                    component: 'admin/organisations/[org_id]',
                  },
                  {
                    path: 'report',
                    component: 'admin/organisations/[org_id]/report',
                  },
                  {
                    path: 'billing',
                    component: 'admin/organisations/[org_id]/billing',
                  },
                ],
              },
            ],
          },
          {
            path: 'customers',
            component: 'admin/customers/layout',
            children: [
              {
                index: true,
                component: 'admin/customers',
              },
              {
                path: ':id',
                component: 'admin/customers/[id]',
              },
            ],
          },
          {
            path: 'contacts',
            component: 'admin/contacts/layout',
            children: [
              {
                index: true,
                component: 'admin/contacts',
              },
            ],
          },
        ],
      },
      {
        path: ':org_id',
        component: <RootOrgLayout />,
        children: [
          {
            index: true,
            component: '[org_id]',
          },
          {
            path: 'test',
            component: '[org_id]/test/layout',
            children: [
              {
                component: '[org_id]/test',
                index: true,
              },
              {
                component: '[org_id]/test/table',
                path: 'table',
              },
              {
                component: '[org_id]/test/settings',
                path: 'settings',
              },
              {
                component: '[org_id]/test/list',
                path: 'list',
              },
              {
                component: '[org_id]/test/report',
                path: 'report',
              },
            ],
          },
          { path: '2022', component: '[org_id]/test' },
          {
            path: 'marketplace',
            component: '[org_id]/marketplace/layout',
            children: [
              {
                path: 'apps',
                component: '[org_id]/marketplace/apps/layout',
                children: [
                  {
                    index: true,
                    component: '[org_id]/marketplace/apps',
                  },
                  {
                    path: ':name',
                    component: '[org_id]/marketplace/apps/[name]',
                  },
                  {
                    path: 'airship',
                    component: '[org_id]/marketplace/apps/airship',
                  },
                  {
                    path: 'fydelia',
                    component: '[org_id]/marketplace/apps/fydelia',
                  },
                  {
                    path: 'orderswift',
                    component: '[org_id]/marketplace/apps/orderswift',
                  },
                  {
                    path: 'unifi',
                    component: '[org_id]/marketplace/apps/unifi',
                  },
                  {
                    path: 'storekit',
                    component: '[org_id]/marketplace/apps/storekit',
                  },
                  {
                    path: 'roller',
                    component: '[org_id]/marketplace/apps/roller',
                  },
                  {
                    path: 'stripe',
                    component: '[org_id]/marketplace/apps/stripe',
                  },
                  {
                    path: 'mailchimp',
                    component: '[org_id]/marketplace/apps/mailchimp',
                  },
                  {
                    path: 'paypal',
                    component: '[org_id]/marketplace/apps/paypal',
                  },
                  {
                    path: 'campaign-monitor',
                    component: '[org_id]/marketplace/apps/campaign-monitor',
                  },
                  {
                    path: 'loke',
                    component: '[org_id]/marketplace/apps/loke',
                  },
                  {
                    path: 'toggle',
                    component: '[org_id]/marketplace/apps/toggle',
                  },
                  {
                    path: 'resdiary',
                    component: '[org_id]/marketplace/apps/resdiary',
                  },
                  {
                    path: 'hart-pms',
                    component: '[org_id]/marketplace/apps/hart-pms',
                  },
                  {
                    path: 'opentable',
                    component: '[org_id]/marketplace/apps/opentable',
                  },
                  {
                    path: 'access-dmn',
                    component: '[org_id]/marketplace/apps/access-dmn',
                  },
                  {
                    path: 'zapier',
                    component: '[org_id]/marketplace/apps/zapier',
                  },
                ],
              },
              {
                path: 'api-keys',
                component: '[org_id]/marketplace/api-keys',
              },
            ],
          },
          {
            path: 'self-serve',
            component: '[org_id]/self-serve/layout',
            children: [
              {
                index: true,
                component: '[org_id]/self-serve',
              },
              {
                path: 'csv',
                component: '[org_id]/marketing/data/upload',
              },
              {
                path: 'campaign/:id',
                component: '[org_id]/marketing/email/campaigns/[id]/layout',
                children: [
                  {
                    index: true,
                    component: '[org_id]/marketing/email/campaigns/[id]',
                  },
                  {
                    path: 'reports',
                    component:
                      '[org_id]/marketing/email/campaigns/[id]/reports/layout',
                    children: [
                      {
                        index: true,
                        component:
                          '[org_id]/marketing/email/campaigns/[id]/reports',
                      },
                      {
                        path: 'sent',
                        component:
                          '[org_id]/marketing/email/campaigns/[id]/reports/sent',
                      },
                      {
                        path: 'events',
                        component:
                          '[org_id]/marketing/email/campaigns/[id]/reports/events',
                      },
                      {
                        path: 'failed',
                        component:
                          '[org_id]/marketing/email/campaigns/[id]/reports/failed',
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            path: 'customer',
            component: '[org_id]/customer/layout',
            children: [
              {
                index: true,
                component: '[org_id]/customer',
              },
              {
                path: 'profile-redirect/:id',
                component: '[org_id]/customer/profile_redirect',
              },
              {
                path: ':id',
                component: '[org_id]/customer/[id]/layout',
                children: [
                  {
                    index: true,
                    component: '[org_id]/customer/[id]',
                  },
                  {
                    path: 'bookings',
                    component: '[org_id]/customer/[id]/bookings',
                  },
                  {
                    path: 'gift',
                    component: '[org_id]/customer/[id]/gift',
                  },
                  {
                    path: 'reviews',
                    component: '[org_id]/customer/[id]/reviews',
                  },
                  {
                    path: 'marketing',
                    component: '[org_id]/customer/[id]/marketing',
                  },
                  {
                    path: 'transactions',
                    component: '[org_id]/customer/[id]/transactions',
                  },
                  {
                    path: 'wi-fi',
                    component: '[org_id]/customer/[id]/wi-fi',
                  },
                  {
                    path: 'activity',
                    component: '[org_id]/customer/[id]/activity',
                  },
                  {
                    path: 'email',
                    component: '[org_id]/customer/[id]/email',
                  },
                ],
              },
            ],
          },

          /**
           * ALL INBOX ROUTES HERE
           */
          {
            path: 'inbox',
            component: '[org_id]/inbox',
          },

          /**
           * ALL MARKETING ROUTES HERE
           */

          {
            path: 'marketing',
            component: '[org_id]/marketing/layout',
            children: [
              {
                index: true,
                component: '[org_id]/marketing',
              },

              /**
               * NEW ROUTING START
               */
              {
                path: 'email',
                component: '[org_id]/marketing/email/layout',
                children: [
                  {
                    path: 'campaigns',
                    component: '[org_id]/marketing/email/campaigns/layout',
                    children: [
                      {
                        index: true,
                        component: '[org_id]/marketing/email/campaigns',
                      },
                      {
                        path: ':id',
                        component:
                          '[org_id]/marketing/email/campaigns/[id]/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/marketing/email/campaigns/[id]',
                          },
                          {
                            path: 'revisions',
                            component:
                              '[org_id]/marketing/email/campaigns/[id]/revisions',
                          },
                          {
                            path: 'quick',
                            component:
                              '[org_id]/marketing/email/campaigns/[id]/quick',
                          },
                          {
                            path: 'reports',
                            component:
                              '[org_id]/marketing/email/campaigns/[id]/reports/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/marketing/email/campaigns/[id]/reports',
                              },
                              {
                                path: 'sent',
                                component:
                                  '[org_id]/marketing/email/campaigns/[id]/reports/sent',
                              },
                              {
                                path: 'events',
                                component:
                                  '[org_id]/marketing/email/campaigns/[id]/reports/events',
                              },
                              {
                                path: 'failed',
                                component:
                                  '[org_id]/marketing/email/campaigns/[id]/reports/failed',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'templates',
                    component: '[org_id]/marketing/email/templates/layout',
                    children: [
                      {
                        index: true,
                        component: '[org_id]/marketing/email/templates',
                      },
                      {
                        path: ':id',
                        component:
                          '[org_id]/marketing/email/templates/[id]/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/marketing/email/templates/[id]',
                          },
                          {
                            component:
                              '[org_id]/marketing/email/templates/[id]/revisions',
                            path: 'revisions',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'domains',
                    component: '[org_id]/marketing/email/domains',
                  },
                  {
                    path: 'audit',
                    component: '[org_id]/marketing/email/audit/layout',
                    children: [
                      {
                        component: '[org_id]/marketing/email/audit',
                        index: true,
                      },
                      {
                        component: '[org_id]/marketing/email/audit/[id]/layout',
                        path: ':id',
                        children: [
                          {
                            component: '[org_id]/marketing/email/audit/[id]',
                            index: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'verification-emails',
                    component: '[org_id]/marketing/email/verification-emails',
                  },
                ],
              },

              {
                component: '[org_id]/marketing/foh/layout',
                path: 'foh',
                children: [
                  {
                    component: '[org_id]/marketing/foh',
                    index: true,
                  },
                  {
                    component: '[org_id]/marketing/foh/menus',
                    path: 'menus',
                    children: [
                      {
                        path: ':id',
                        component: './components/Menu/MenuItem',
                      },
                    ],
                  },
                  {
                    path: 'vouchers',
                    component: '[org_id]/marketing/foh/vouchers/layout',
                    children: [
                      {
                        index: true,
                        component: '[org_id]/marketing/foh/vouchers',
                      },
                      {
                        path: ':id',
                        component:
                          '[org_id]/marketing/foh/vouchers/[id]/layout',
                        children: [
                          {
                            index: true,
                            component: '[org_id]/marketing/foh/vouchers/[id]',
                          },
                          {
                            path: 'performance',
                            component:
                              '[org_id]/marketing/foh/vouchers/[id]/performance',
                          },
                          {
                            path: 'imports',
                            component:
                              '[org_id]/marketing/foh/vouchers/[id]/imports',
                          },
                          {
                            path: 'code-imports/:importId',
                            component:
                              '[org_id]/marketing/foh/vouchers/[id]/code-imports/:importId/import',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                component: '[org_id]/marketing/website/layout',
                path: 'website',
                children: [
                  {
                    path: 'footprint',
                    component: '[org_id]/marketing/website/footprint/layout',
                    children: [
                      {
                        index: true,
                        component: '[org_id]/marketing/website/footprint',
                      },
                      {
                        path: 'customers',
                        component:
                          '[org_id]/marketing/website/footprint/customers',
                      },
                      {
                        path: 'settings',
                        component:
                          '[org_id]/marketing/website/footprint/settings',
                      },
                    ],
                  },
                  {
                    path: 'forms',
                    component: '[org_id]/marketing/website/forms',
                  },
                  {
                    path: 'links',
                    component: '[org_id]/marketing/website/links',
                  },
                ],
              },
              /**
               * NEW ROUTING END
               */
              {
                component: '[org_id]/marketing/sms/layout',
                path: 'sms',
                children: [
                  {
                    component: '[org_id]/marketing/sms/credits',
                    path: 'credits',
                  },
                  {
                    component: '[org_id]/marketing/sms/senders',
                    path: 'senders',
                  },
                  {
                    path: 'audit',
                    component: '[org_id]/marketing/sms/audit',
                    children: [
                      {
                        path: ':id/events',
                        component: '[org_id]/marketing/sms-audit/single',
                      },
                    ],
                  },
                  {
                    path: 'ledger',
                    component: '[org_id]/marketing/sms/ledger',
                  },
                  {
                    component: '[org_id]/marketing/sms/campaigns/layout',
                    path: 'campaigns',
                    children: [
                      {
                        component: '[org_id]/marketing/sms/campaigns',
                        index: true,
                      },
                      {
                        component:
                          '[org_id]/marketing/sms/campaigns/[id]/layout',
                        path: ':id',
                        children: [
                          {
                            component: '[org_id]/marketing/sms/campaigns/[id]',
                            index: true,
                          },
                          {
                            path: 'reports',
                            component:
                              '[org_id]/marketing/sms/campaigns/[id]/reports/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/marketing/sms/campaigns/[id]/reports',
                              },
                              {
                                path: 'sent',
                                component:
                                  '[org_id]/marketing/sms/campaigns/[id]/reports/sent',
                              },
                              {
                                path: 'events',
                                component:
                                  '[org_id]/marketing/sms/campaigns/[id]/reports/events',
                              },
                              {
                                path: 'failed',
                                component:
                                  '[org_id]/marketing/sms/campaigns/[id]/reports/failed',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                path: 'data',
                component: '[org_id]/marketing/data/layout',
                children: [
                  {
                    index: true,
                    component: '[org_id]/marketing/data',
                  },
                  {
                    path: 'segments/all',
                    component: '[org_id]/marketing/data/segment/all',
                  },
                  {
                    path: 'segments/:id',
                    component: '[org_id]/marketing/data/segment/[id]',
                  },
                  {
                    path: 'upload',
                    component: '[org_id]/marketing/data/upload',
                  },
                  {
                    path: 'upload/all-files',
                    component: '[org_id]/marketing/data/upload/all-files',
                  },
                  {
                    path: 'ideas',
                    component: '[org_id]/marketing/data/ideas',
                  },
                  {
                    path: 'suppression',
                    component: '[org_id]/marketing/data/suppression',
                  },
                  {
                    path: 'meta',
                    component: '[org_id]/marketing/data/meta/page',
                  },
                ],
              },
            ],
          },
          /**
           * ALL REVIEWS ROUTES HERE
           */
          {
            path: 'reviews',
            component: '[org_id]/reviews/layout',
            children: [
              {
                index: true,
                component: '[org_id]/reviews',
              },
              {
                path: 'responses',
                component: '[org_id]/reviews/responses/layout',
                children: [
                  {
                    index: true,
                    component: '[org_id]/reviews/responses',
                  },
                  {
                    path: ':id',
                    component: '[org_id]/reviews/responses/[id]',
                  },
                ],
              },
              {
                component: '[org_id]/reviews/pages/layout',
                path: 'pages',
                children: [
                  {
                    index: true,
                    component: '[org_id]/reviews/pages',
                  },
                  {
                    path: ':id',
                    component: '[org_id]/reviews/pages/[id]',
                  },
                ],
              },
              {
                component: '[org_id]/reviews/boost',
                path: 'boost',
              },
              {
                component: '[org_id]/reviews/reports',
                path: 'reports',
              },
            ],
          },
          /**
           * ALL VENUES ROUTES HERE
           */
          {
            path: 'venues',
            component: '[org_id]/venues/layout',
            children: [
              {
                index: true,
                component: '[org_id]/venues',
              },
              {
                path: ':serial',
                component: '[org_id]/venues/[serial]/layout',
                handle: {
                  crumb: (data) => {
                    return (
                      <DropdownMenu>
                        <DropdownMenuTrigger className="flex items-center gap-1 focus:outline-none">
                          <BreadcrumbLink href={data.pathname}>
                            Venue
                          </BreadcrumbLink>
                          <span className="sr-only">Toggle menu</span>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start">
                          <DropdownMenuItem>
                            <NavLink
                              className="size-full"
                              to={`${data.pathname}/inbox`}
                            >
                              Inbox
                            </NavLink>
                          </DropdownMenuItem>
                          <DropdownMenuItem>
                            <NavLink
                              className="size-full"
                              to={`${data.pathname}/bookings`}
                            >
                              Bookings
                            </NavLink>
                          </DropdownMenuItem>
                          <DropdownMenuItem>
                            <NavLink
                              className="size-full"
                              to={`${data.pathname}/loyalty`}
                            >
                              Loyalty
                            </NavLink>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    )
                  },
                },
                children: [
                  {
                    index: true,
                    component: '[org_id]/venues/[serial]',
                  },
                  {
                    path: 'in-the-building',
                    component: '[org_id]/venues/[serial]/in-the-building',
                  },
                  {
                    path: 'inbox',
                    component: '[org_id]/inbox',
                  },
                  {
                    path: 'reviews',
                    component: '[org_id]/reviews/responses',
                  },
                  {
                    path: 'brand',
                    component: '[org_id]/venues/[serial]/brand/layout',
                    children: [
                      {
                        index: true,
                        component: '[org_id]/venues/[serial]/brand',
                      },
                      {
                        path: 'wi-fi',
                        component: '[org_id]/venues/[serial]/wi-fi/branding',
                      },
                      {
                        path: 'email',
                        component: '[org_id]/venues/[serial]/brand/email',
                      },
                      {
                        path: 'reviews',
                        component: '[org_id]/venues/[serial]/brand/reviews',
                      },
                      {
                        path: 'bookings',
                        component: '[org_id]/venues/[serial]/brand/booking',
                      },
                      {
                        path: 'gift-cards',
                        component: '[org_id]/venues/[serial]/brand/gift-cards',
                      },
                      {
                        path: 'forms',
                        component: '[org_id]/venues/[serial]/brand/forms',
                      },
                      {
                        path: 'journey',
                        component: '[org_id]/venues/[serial]/brand/journey',
                      },
                    ],
                  },
                  {
                    path: 'wi-fi',
                    component: '[org_id]/venues/[serial]/wi-fi/layout',
                    children: [
                      {
                        index: true,
                        component: '[org_id]/venues/[serial]/wi-fi/activity',
                      },
                      {
                        path: 'settings',
                        component: '[org_id]/venues/[serial]/wi-fi/settings',
                      },
                      {
                        path: 'payments',
                        component: '[org_id]/venues/[serial]/wi-fi/payments',
                      },
                      {
                        path: 'sessions',
                        component: '[org_id]/venues/[serial]/wi-fi/sessions',
                      },
                      {
                        path: 'activity',
                        component: '[org_id]/venues/[serial]/wi-fi/activity',
                      },
                      {
                        path: 'upsell',
                        component:
                          '[org_id]/venues/[serial]/wi-fi/upsell/layout',
                        children: [
                          {
                            index: true,
                            component: '[org_id]/venues/[serial]/wi-fi/upsell',
                          },
                          {
                            path: 'events',
                            component:
                              '[org_id]/venues/[serial]/wi-fi/upsell/events',
                          },
                          {
                            path: 'performance',
                            component:
                              '[org_id]/venues/[serial]/wi-fi/upsell/performance',
                          },
                        ],
                      },
                      {
                        path: 'survey',
                        component: '[org_id]/venues/[serial]/wi-fi/survey',
                      },
                      {
                        path: 'hardware',
                        component: '[org_id]/venues/[serial]/wi-fi/hardware',
                      },
                      {
                        path: 'design',
                        component:
                          '[org_id]/venues/[serial]/wi-fi/branding/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/wi-fi/branding',
                          },
                        ],
                      },
                    ],
                  },

                  {
                    path: 'bookings',
                    component: '[org_id]/venues/[serial]/bookings/layout',
                    handle: {
                      crumb: (data) => {
                        return (
                          <BreadcrumbLink href={data.pathname}>
                            Booking
                          </BreadcrumbLink>
                        )
                      },
                    },
                    children: [
                      {
                        index: true,
                        component: '[org_id]/venues/[serial]/bookings',
                      },
                      {
                        path: 'configuration',
                        component:
                          '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/covers',
                          },
                          {
                            path: 'covers',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/covers',
                          },
                          {
                            path: 'intervals',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/intervals',
                          },
                          {
                            path: 'details',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/details',
                          },
                          {
                            path: 'user-actions',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/user_actions',
                          },
                          {
                            path: 'options',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/options',
                          },
                          {
                            path: 'service-hours',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/service-hours',
                          },
                          {
                            path: 'reminders',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/reminders',
                          },
                          {
                            path: 'payments',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/payments',
                          },
                          {
                            path: 'messages',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/messages',
                          },
                          {
                            path: 'tracking',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/tracking',
                          },
                        ],
                      },
                      {
                        path: 'special-dates',
                        component:
                          '[org_id]/venues/[serial]/bookings/config/[id]/settings/special-dates/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/special-dates/dates',
                          },
                          {
                            path: 'ideas',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/special-dates/ideas',
                          },
                          {
                            path: 'archived',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/special-dates/archived',
                          },
                        ],
                      },
                      {
                        path: 'list',
                        component: '[org_id]/venues/[serial]/bookings/list',
                      },
                      {
                        path: 'inbox',
                        component: '[org_id]/inbox',
                      },

                      {
                        path: 'day-planner',
                        component:
                          '[org_id]/venues/[serial]/bookings/day-planner/layout',
                        children: [
                          {
                            path: 'inbox',
                            component: '[org_id]/inbox',
                          },
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/day-planner',
                          },
                        ],
                      },
                      {
                        path: 'design',
                        component: '[org_id]/venues/[serial]/bookings/design',
                      },

                      {
                        path: 'booking-types',
                        component:
                          '[org_id]/venues/[serial]/bookings/config/[id]/settings/booking-types/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/booking-types',
                          },
                          {
                            path: 'ideas',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/booking-types/ideas',
                          },
                          {
                            path: 'archived',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/booking-types/archived',
                          },
                        ],
                      },
                      {
                        path: 'google',
                        component:
                          '[org_id]/venues/[serial]/bookings/google/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/google/settings',
                          },
                          {
                            path: 'information',
                            component:
                              '[org_id]/venues/[serial]/bookings/google/information',
                          },
                        ],
                      },
                      {
                        path: 'automations',
                        component:
                          '[org_id]/venues/[serial]/bookings/automations/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/automations/settings',
                          },
                          {
                            path: 'stats',
                            component:
                              '[org_id]/venues/[serial]/bookings/automations/stats',
                          },
                          {
                            path: ':automation_source_id',
                            component:
                              '[org_id]/venues/[serial]/bookings/automations/settings/[id]/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/bookings/automations/settings/[id]',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'reporting',
                        component:
                          '[org_id]/venues/[serial]/bookings/reporting/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/reporting/stats',
                          },
                          {
                            path: 'actions',
                            component:
                              '[org_id]/venues/[serial]/bookings/reporting/actions',
                          },
                        ],
                      },
                      {
                        path: 'embed-links',
                        component:
                          '[org_id]/venues/[serial]/bookings/embed-links/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/embed-links',
                          },
                          {
                            path: 'stand-alone-booking',
                            component:
                              '[org_id]/venues/[serial]/bookings/embed-links/stand-alone-booking',
                          },
                          {
                            path: 'tablet-mode',
                            component:
                              '[org_id]/venues/[serial]/bookings/embed-links/tablet-mode',
                          },
                          {
                            path: 'embed-widget',
                            component:
                              '[org_id]/venues/[serial]/bookings/embed-links/widget',
                          },
                        ],
                      },
                      {
                        path: 'tables',
                        component:
                          '[org_id]/venues/[serial]/bookings/tables/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/tables',
                          },
                          {
                            path: 'archived',
                            component:
                              '[org_id]/venues/[serial]/bookings/tables/archived',
                          },
                          {
                            path: 'areas',
                            component:
                              '[org_id]/venues/[serial]/bookings/tables/areas/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/bookings/tables/areas',
                              },
                              {
                                path: 'archived',
                                component:
                                  '[org_id]/venues/[serial]/bookings/tables/areas/archived',
                              },
                            ],
                          },
                          {
                            path: 'combinations',
                            component:
                              '[org_id]/venues/[serial]/bookings/tables/combinations/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/bookings/tables/combinations',
                              },
                              {
                                path: 'archived',
                                component:
                                  '[org_id]/venues/[serial]/bookings/tables/combinations/archived',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'combinations',
                        component:
                          '[org_id]/venues/[serial]/bookings/config/[id]/settings/combinations/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/combinations/combos',
                          },
                        ],
                      },
                      {
                        path: 'areas',
                        component:
                          '[org_id]/venues/[serial]/bookings/config/[id]/settings/areas/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/areas/areas_order',
                          },
                          {
                            path: 'archived',
                            component:
                              '[org_id]/venues/[serial]/bookings/config/[id]/settings/areas/archived',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'loyalty',
                    component: '[org_id]/venues/[serial]/loyalty/layout',
                    children: [
                      {
                        index: true,
                        component: '[org_id]/venues/[serial]/loyalty',
                      },
                      {
                        path: ':loyalty_scheme_id',
                        component:
                          '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/layout',
                        children: [
                          {
                            index: true,
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]',
                          },
                          {
                            path: 'service',
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/service/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/service',
                              },
                              {
                                path: ':loyalty_scheme_user_profile_id',
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers/[loyalty_scheme_user_profile_id]',
                              },
                            ],
                          },
                          {
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/go-live/layout',
                            path: 'go-live',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/go-live',
                              },
                            ],
                          },
                          {
                            path: 'customers',
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers',
                              },
                              {
                                path: ':loyalty_scheme_user_profile_id',
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers/[loyalty_scheme_user_profile_id]/layout',
                                children: [
                                  {
                                    index: true,
                                    component:
                                      '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers/[loyalty_scheme_user_profile_id]',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            path: 'interaction-history',
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/interaction-history/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/interaction-history',
                              },
                            ],
                          },
                          {
                            path: 'automations',
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/automations/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/automations',
                              },
                            ],
                          },
                          {
                            path: 'rewards',
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards',
                              },
                              {
                                path: 'create',
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/create/layout',
                                children: [
                                  {
                                    index: true,
                                    component:
                                      '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/create',
                                  },
                                ],
                              },
                              {
                                path: ':reward_id',
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/[reward_id]/layout',
                                children: [
                                  {
                                    index: true,
                                    component:
                                      '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/[reward_id]',
                                  },
                                ],
                              },
                              {
                                path: 'archived',
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/archived/layout',
                                children: [
                                  {
                                    index: true,
                                    component:
                                      '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/archived',
                                  },
                                ],
                              },
                              {
                                path: 'vouchers',
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/vouchers/layout',
                                children: [
                                  {
                                    index: true,
                                    component:
                                      '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/vouchers',
                                  },
                                  {
                                    path: 'upload',
                                    component:
                                      '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/vouchers/upload/layout',
                                    children: [
                                      {
                                        index: true,
                                        component:
                                          '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/vouchers/upload',
                                      },
                                    ],
                                  },
                                  // {
                                  //   path: ":voucher_id",
                                  //   component: "[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/vouchers/[voucher_id]/layout",
                                  //   children: [
                                  //     {
                                  //       index: true,
                                  //       component: "[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/vouchers/[voucher_id]",
                                  //     },
                                  //   ],
                                  // },
                                  // {
                                  //   path: "archived",
                                  //   component: "[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/vouchers/archived/layout",
                                  //   children: [
                                  //     {
                                  //       index: true,
                                  //       component: "[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/vouchers/archived",
                                  //     },
                                  //   ],
                                  // },
                                ],
                              },
                            ],
                          },
                          {
                            path: 'gifts',
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts',
                              },
                              {
                                path: 'archived',
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/archived/layout',
                                children: [
                                  {
                                    index: true,
                                    component:
                                      '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/archived',
                                  },
                                ],
                              },
                              {
                                path: ':gift_id',
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/[gift_id]/layout',
                                children: [
                                  {
                                    index: true,
                                    component:
                                      '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/[gift_id]',
                                  },
                                ],
                              },
                              {
                                path: 'create',
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/create/layout',
                                children: [
                                  {
                                    index: true,
                                    component:
                                      '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/create',
                                  },
                                ],
                              },
                            ],
                          },

                          {
                            path: 'passes',
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/passes/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/passes',
                              },
                            ],
                          },
                          {
                            path: 'staff',
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/staff/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/staff',
                              },
                            ],
                          },
                          {
                            path: 'redeem',
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/redeem/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/redeem',
                              },
                              {
                                path: ':encrypted_qr_code_data',
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/redeem/[encrypted_qr_code_data]/layout',
                                children: [
                                  {
                                    index: true,
                                    component:
                                      '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/redeem/[encrypted_qr_code_data]',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            path: 'points',
                            component:
                              '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/points/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  '[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/points',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'settings',
                    component: '[org_id]/venues/[serial]/settings/layout',
                    children: [
                      {
                        index: true,
                        component: '[org_id]/venues/[serial]/settings',
                      },

                      {
                        path: 'integrations',
                        component:
                          '[org_id]/venues/[serial]/settings/integrations',
                      },
                      {
                        path: 'network',
                        component: '[org_id]/venues/[serial]/settings/network',
                      },
                    ],
                  },

                  {
                    path: 'reports',
                    component: '[org_id]/venues/[serial]/reports/layout',
                    children: [
                      {
                        path: 'interactions',
                        component: '[org_id]/reports/interactions',
                      },
                      {
                        path: 'customers',
                        component: '[org_id]/reports/customers',
                      },
                      {
                        path: 'origin',
                        component: '[org_id]/reports/origin',
                      },
                      {
                        path: 'transactions',
                        component: '[org_id]/reports/transactions',
                      },
                      {
                        index: true,
                        component: '[org_id]/reports/customers',
                      },
                      {
                        path: 'registrations',
                        component:
                          '[org_id]/venues/[serial]/reports/registrations',
                      },
                      {
                        path: 'customers',
                        component: '[org_id]/reports/customers',
                      },
                      {
                        path: 'visits',
                        component: '[org_id]/venues/[serial]/reports/visits',
                      },
                      {
                        path: 'devices',
                        component: '[org_id]/venues/[serial]/reports/devices',
                      },
                      {
                        path: 'bandwidth',
                        component: '[org_id]/venues/[serial]/reports/bandwidth',
                      },
                      {
                        path: 'payments',
                        component: '[org_id]/venues/[serial]/reports/payments',
                      },
                      {
                        path: 'reviews',
                        component: '[org_id]/reviews/reports',
                      },
                      {
                        path: 'bookings',
                        component:
                          '[org_id]/venues/[serial]/bookings/reporting/stats',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          /**
           * LOYALTY OTHER ROUTES HERE
           * 
           *  {
    path: '/gifting',
    component: AsyncGifting,
  },
  {
    path: '/gifting/activations',
    component: AsyncGiftingActivations,
  },
  {
    path: '/gifting/reports',
    component: AsyncGiftingReports,
  },
  {
    path: '/gifting/:id',
    component: AsyncGiftingSingle,
  },

           */
          {
            path: 'loyalty',
            component: '[org_id]/venues/[serial]/loyalty',
          },
          {
            path: 'loyalty-legacy',
            component: '[org_id]/loyalty/layout',
            children: [
              {
                index: true,
                component: '[org_id]/loyalty',
              },
              {
                path: 'new',
                component: '[org_id]/loyalty/new',
              },
              {
                path: ':id',
                component: '[org_id]/loyalty/[id]/layout',
                children: [
                  {
                    index: true,
                    component: '[org_id]/loyalty/[id]',
                  },
                  {
                    path: 'customers',
                    component: '[org_id]/loyalty/[id]/customers',
                  },
                  {
                    path: 'redemptions',
                    component: '[org_id]/loyalty/[id]/redemptions',
                  },
                  {
                    path: 'tags',
                    component: '[org_id]/loyalty/[id]/tags',
                  },
                  {
                    path: 'scheme',
                    component: '[org_id]/loyalty/[id]/scheme',
                  },
                ],
              },
            ],
          },
          {
            path: 'gifting',
            component: '[org_id]/gifting/layout',
            children: [
              {
                index: true,
                component: '[org_id]/gifting',
              },
              {
                path: 'scan',
                component: '[org_id]/gifting/scan',
              },
              {
                path: 'activations',
                component: '[org_id]/gifting/activations',
              },
              {
                path: 'reports',
                component: '[org_id]/gifting/reports',
              },
              {
                path: 'cards',
                component: '[org_id]/gifting/cards',
              },
              {
                path: ':id',
                component: '[org_id]/gifting/[id]/layout',
                children: [
                  {
                    index: true,
                    component: '[org_id]/gifting/[id]',
                  },
                ],
              },
            ],
          },

          /**
           * Reports
           */
          {
            path: 'reports',
            component: '[org_id]/reports/layout',
            children: [
              {
                index: true,
                component: '[org_id]/reports',
              },
              {
                path: 'multisite',

                component: '[org_id]/reports/multisite',
              },
              {
                path: 'origin',
                component: '[org_id]/reports/origin',
              },
              {
                path: 'marketing-email',
                component: '[org_id]/reports/marketing/email',
              },
              {
                path: 'marketing-sms',
                component: '[org_id]/reports/marketing/sms',
              },
              {
                path: 'interactions',
                component: '[org_id]/reports/interactions',
              },
              {
                path: 'customers',
                component: '[org_id]/reports/customers',
              },
              {
                path: 'transactions',
                component: '[org_id]/reports/transactions',
              },
              {
                path: 'my-reports',
                component: '[org_id]/reports/report-builder',
              },
              {
                path: 'monthly-retention',
                component: '[org_id]/reports/cohort/monthly',
              },
              {
                path: 'gifting',
                component: '[org_id]/gifting/reports',
              },
              {
                path: 'tags',
                component: '[org_id]/reports/tags',
              },
              {
                path: 'survey',
                component: '[org_id]/reports/survey',
              },
            ],
          },

          /**
           * ALL OTHER ROUTES HERE
           */

          {
            path: 'account',
            component: '[org_id]/account/layout',
            children: [
              {
                index: true,
                component: '[org_id]/account',
              },
              {
                path: 'profile',
                component: '[org_id]/account/profile',
              },
              {
                path: 'notifications',
                component: '[org_id]/account/notifications',
              },
            ],
          },
          {
            path: 'organisation',
            component: '[org_id]/organisation/layout',
            children: [
              {
                index: true,
                component: '[org_id]/organisation',
              },
              {
                path: 'venues',
                component: '[org_id]/organisation/venues',
              },
              {
                path: 'team',
                component: '[org_id]/organisation/team',
              },
              {
                path: 'groups',
                component: '[org_id]/organisation/groups',
              },
              {
                path: 'settings',
                component: '[org_id]/organisation/settings',
              },
              {
                path: 'tags',
                component: '[org_id]/organisation/tags',
              },
              {
                path: 'billing',
                component: '[org_id]/organisation/billing',
              },
              {
                path: 'billing-2',
                component: '[org_id]/organisation/billing-2',
              },
              {
                path: 'integrations',
                component: '[org_id]/organisation/integrations',
              },
              {
                path: 'event-log',
                component: '[org_id]/organisation/event-log',
              },
              {
                path: 'brand',
                component: '[org_id]/organisation/brand/layout',
                children: [
                  {
                    index: true,
                    component: '[org_id]/organisation/brand',
                  },
                  {
                    path: ':id',
                    component: '[org_id]/organisation/brand/[id]/layout',
                    children: [
                      {
                        index: true,
                        component: '[org_id]/organisation/brand/[id]',
                      },
                      {
                        path: 'journey',
                        component: '[org_id]/organisation/brand/[id]/journey',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]
