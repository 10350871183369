import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { SIconButton, cx } from 'tailwind-ui'
import { XMarkIcon } from '@heroicons/react/16/solid'
import { useInView } from 'react-intersection-observer'
import { useHideZendesk } from '@/helpers/zendesk'

function Drawer({
  open = true,
  setOpen,
  children,
  width = 'w-full lg:max-w-7xl',
  title = '',
  classNames = {
    container: 'lg:max-w-7xl',
  },
}: {
  open: boolean
  setOpen: (isOpen: boolean) => void
  children: React.ReactNode
  width?: string
  title: string
  classNames?: {
    container: string
  }
}) {
  const handleDrawerClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  const [inViewRef, isInView] = useInView()
  useHideZendesk(isInView)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <div aria-hidden="true" className="fixed inset-0 bg-black/50" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={cx(
                'pointer-events-none fixed inset-y-0 right-0 flex max-w-full lg:pl-16 ',
                classNames.container
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-50 sm:duration-100"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-50 sm:duration-100"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={`flex flex-col pointer-events-auto w-screen shadow-md ${width} bg-gray-50 dark:bg-gray-950 lg:border-l`}
                  ref={inViewRef}
                >
                  <div className="border-b flex px-4 py-1.5 min-h-[54px] justify-between items-center bg-white dark:bg-black">
                    <SIconButton
                      icon={XMarkIcon}
                      variant="ghost_default"
                      type="button"
                      onClick={() => {
                        setOpen(false)
                      }}
                    />
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold capitalize leading-6 text-gray-900 dark:text-gray-50"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="w-[40px]" />
                  </div>
                  <div className="flex-1 overflow-x-scroll overflow-y-scroll">
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default Drawer
